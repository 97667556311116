import $, { ajax } from 'jquery';
import { ujsGet } from "./ujs_helpers";
import initDatatables from './angle/modules/tables/datatable';
import 'bootstrap-filestyle'
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';

initForm()

function initForm() {
  $(".filestyle").filestyle();
  $('.datepicker-filter').datepicker({
    format: "dd-mm-yyyy",
    orientation: "bottom",
    autoclose: true
  });
}

window.initFeedTrafficReportForm = initForm;
window.initDatatables = initDatatables;


$(document).on('change', '.selected-date', function () {
  $('#select-account').empty().append('<option value="">Chose Account</option>')
  ujsGet(
    "/feed_traffic_reports/form_fields",
    { date: $(this).val() },
    () => {
      initForm()
    }
  )
})


$(document).on('change', '.selected-account', function () {
  ujsGet(
    "/feed_traffic_reports/form_fields",
    { date: $('.selected-date').val(), account_id: $(this).val() },
    () => {
      initForm()
    }
  )
})


$(document).on('change', '.selected-feed-data-service', function () {
  ujsGet(
    "/feed_traffic_reports/form_fields",
    { date: $('.selected-date').val(), account_id: $('.selected-account').val(), feed_data_service_id: $(this).val() },
    () => {
      initForm()
    }
  )
})


$( document ).ready(function() {
  $('.feed-advertiser-select2').select2({
  });
});

$(document).on('select2:select', '.advertiser-select2', function (e) {
  ajax({
    url: '/feeds',
    dataType: 'json',
    data: { account_id: $(this).val() },
    success: function(data) {
      let options = data.map(function(feed) {
        return { id: feed.id, text: feed.name };
      });
      options.sort((a, b) => {
        const nameA = a.text.toUpperCase(); // ignore upper and lowercase
        const nameB = b.text.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        } else {
          return 1;
        }
      });
      options = [{ id: '', text: 'All' }].concat(options);

      $('.advertiser-feed-select2').empty().select2({
        data: options
      });
    }
  });
});

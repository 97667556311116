import $ from 'jquery';
import 'bootstrap/js/dist/popover';

function preInitXEditable() {
  if ($('.x-editable-card').length) {
    import('x-editable-bs4/dist/bootstrap4-editable/css/bootstrap-editable.css').then(() => {
      import('x-editable-bs4/dist/bootstrap4-editable/js/bootstrap-editable.min.js').then(() => {
        initXEditable();
      });
    });
  }
}

function editableParams(params) {
  params.authenticity_token = $('[name="csrf-token"]')[0].content;
  const ptParams = {};
  ptParams[params.name] = params.value;
  params.blend_feed_tag = ptParams;
  return params;
}

function initXEditable() {
    if (!$.fn.editable) return

    // Font Awesome support
    $.fn.editableform.buttons =
        '<button type="submit" class="btn btn-primary btn-sm editable-submit">' +
        '<i class="fa fa-fw fa-check"></i>' +
        '</button>' +
        '<button type="button" class="btn btn-default btn-sm editable-cancel">' +
        '<i class="fa fa-fw fa-times"></i>' +
        '</button>';

    //defaults
    $.fn.editable.defaults.ajaxOptions = { type: "PUT" };

    $('.editable-blend-weight').editable({
      mode: 'inline',
      clear: false,
      validate: function(value) {
          const numberValue = parseFloat(value);
          if (isNaN(numberValue)) return 'This field should be a number';
      },
      params: (params) => { return editableParams(params) },
      success: (response, newValue) => {
        initXEditable();
      }
    });
}

$(function() {
  window.blendPreInitXEditable = preInitXEditable;
  preInitXEditable();
});
